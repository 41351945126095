html {
  font-size: 62.5%;

  &.sr .load-hidden {
    // visibility: hidden;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
